export const EnTranslations = {
  surveys: "Surveys",
  survey: "Survey",
  questions: "Questions",
  question: "Question",
  answer: "Answer",
  settings: "Settings",
  create: "Create",
  cancel: "Cancel",
  save: "Save",
  show: "View",
  back: "Back",
  delete: "Delete",
  tags: "Tags",
  select: "Select",
  enter: "Enter",
  oneOf: "One of",
  general: "General",
  schedule: "Schedule",
  respondents: "Recipients",
  search: "Search",
  generateQr: "Generate QR",
  searchBySurveys: "Search surveys",
  questionnaireQuestions: "Questionnaire Questions",
  personalization: "Decor",
  integrations: "Integrations",
  results: "Results",
  date: "Date",
  time: "Time",
  days: "Days of the week",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
  mondayShortLabel: "Mon",
  tuesdayShortLabel: "Tue",
  wednesdayShortLabel: "Wed",
  thursdayShortLabel: "Thu",
  fridayShortLabel: "Fri",
  saturdayShortLabel: "Sat",
  sundayShortLabel: "Sun",
  today: "Today",
  dailyDistributionSize: "Daily distribution size",
  exclusionDates: "Exception dates",
  exclusionDatesHelpTxt:
    "This function allows you to mark the dates when sending surveys to subscribers is prohibited.",
  apiKey: "API-Key",
  apiKeyDeleteConfirmation: "Are you sure you want to delete the API Key ？",
  apiKeyHelpTxt: "The function allows you to manage the API keys necessary to authorize API requests to send surveys.",
  description: "Description",
  actions: "Actions",
  action: "Action",
  apiKeyCopiedToCopyBoard: "The API-Key has been copied to the clipboard.",
  notOneOf: "Any except",
  createSurvey: "Create a survey",
  fromTemplate: "From template",
  surveyName: "Name of the survey",
  surveyNameTooltip: "The survey name is displayed as a title on each survey page for the respondent.",
  type: "Type",
  recipientsSource: "Recipient Source",
  respondentsListSource: "Source of the list of respondents",
  created: "Created",
  createDate: "Date of creation",
  endDate: "Date of completion",
  startDate: "Start date",
  status: "Status",
  subscribersDb: "Contacts",
  file: "File",
  startSurvey: "Start survey",
  startSurveyConfirmation: "Are you sure you want to start the survey ？",
  endSurvey: "Finish survey",
  endSurveyConfirmation: "Are you sure you want to finish the survey ？",
  pauseSurvey: "Pause survey",
  pauseSurveyConfirmation: "Are you sure you want to pause the survey ？",
  filtration: "Filtration",
  bySubscriberProfile: "By subscriber profile",
  bySurveyProps: "By survey parameters",
  addFiltersGroup: "Add a filter group",
  countSubscribers: "Calculate the number of respondents in the sample",
  selectFile: "Select a file",
  filtersGroupHelpText: "You can create several filter groups that will work according to the logic OR",
  questionName: "Question name",
  template: "Template",
  noData: "No data",
  createCopy: "Create a copy",
  copyLink: "Web link",
  createDuplicate: "Create a duplicate",
  createDuplicateConfirmation: "Are you sure you want to create a duplicate survey ？",
  deleteQuestion: "Delete question",
  deleteQuestionConfirmation: "Are you sure you want to delete the question ？",
  edit: "Edit",
  addTag: "Add tag",
  addFilter: "Add filter",
  questionText: "Question text ",
  questionTextOnEn: "Question text in English",
  questionTextOnKk: "Question text in Kazakh",
  questionTextOnRu: "Question text in Russian",
  questionTextOnFrench: "Question text in French",
  questionTextOnTurkish: "Question text in Turkish",
  questionTextOnUzbek: "Question text in Uzbek",
  onEn: "In English",
  onKk: "In Kazakh",
  onRu: "In Russian",
  onFrench: "In French",
  onTurkish: "In Turkish",
  onUzbek: "In Uzbek",
  useCondition: "Use condition",
  singleChoice: "One of the list",
  multipleChoice: "A few from the list",
  textAnswer: "Text response",
  infoBlock: "Info block",
  endBlock: "End block",
  matrixCsi: "CSI (Customer Satisfaction Index)",
  npsRatingScale: "Rating",
  other: "Other",
  add: "Add",
  params: "Options",
  param: "Option",
  criteria: "Criteria",
  gradeTxt: "Assessment text",
  gradeTxtOnEn: "Assessment text in English",
  gradeTxtOnKk: "Assessment text in Kazakh",
  gradeTxtOnRu: "Assessment text in Russian",
  gradeTxtOnFrench: "Assessment text in French",
  gradeTxtOnTurkish: "Assessment text in Turkish",
  gradeTxtOnUzbek: "Assessment text in Uzbek",
  balls: "points",
  yes: "Yes",
  no: "No",
  yesNo: "Yes / No",
  ratingScale: "Rating scale",
  blockType: "Block type",
  information: "Informational",
  final: "Final",
  answerType: "Response type",
  inputField: "Input field",
  inputFieldFormat: "Input field format",
  oneLine: "One-liner",
  multiLine: "Multiline",
  phone: "Telephone",
  answerRequired: "Answer is required",
  icon: "Icon",
  textValues: "Text values",
  grade: "Grade",
  min: "Min.",
  max: "Max.",
  logo: "Logo",
  logoMinSizeHelpText: "Min. logo size: ",
  logoMaxSizeHelpText: "Max. logo size: ",
  logoMaxWeightHelpText: "Max. file weight: ",
  faviconMaxSizeHelpText: "Max. Favicon size: ",
  themeColor: "Corporate color",
  surveyMetaName: "Browser tab name",
  surveyMetaDescription: "Survey meta description",
  next: "Next",
  answerVariable: "Possible answer",
  personalizationHelpText: "Select a design for your surveys. The logo will be displayed in the upper left corner.",
  errorLabel: "An error has occurred",
  errorWhileSaving: "An error occurred while saving ",
  requiredMessage: "Required to fill",
  successful: "Successfully",
  logout: "Logout",
  userName: "Username",
  password: "Password",
  pleaseEnter: "Please, enter",
  signIn: "Log in",
  authError: "Invalid login information. User not found or password incorrect.",
  deleteSurvey: "Delete survey",
  deleteSurveyConfirmation: "Are you sure you want to delete the survey ？",
  new: "New",
  draft: "Draft",
  active: "Active",
  paused: "Paused",
  finished: "Finished",
  deleted: "Deleted",
  reset: "Reset",
  surveyType: "Survey type",
  subscribers: "Respondents",
  areYouSure: "Are you sure ？",
  qrCode: "QR-Code",
  fillRequiredFields: "Fill in required fields",
  requiredField: "Required to fill",
  useDefaultColor: "Use default color",
  maxCharacters: "Max. characters: 25",
  changesSaved: "Changes saved",
  acceptTypesFormat: "Supported file formats",
  metaDescriptionDefaultText: "We invite you to take the survey at the link",
  selectLogo: "Select logo",
  selectFavicon: "Select Favicon",
  selectIcon: "Select Icon",
  callOn: "Event",
  method: "Method",
  deleteWebhook: "Delete Webhook",
  deleteWebhookConfirmation: "Are you sure you want to delete Webhook ？",
  createWebhook: "Create Webhook",
  request: "Request",
  authorization: "Authorization",
  authorizationInfo: "Authorization data",
  headers: "Headers",
  key: "Key",
  value: "Value",
  format: "Format",
  requestBody: "Request body",
  enterPassword: "Enter password",
  enterOldPassword: "Enter old password",
  enterNewPassword: "Enter a new password",
  confirmPassword: "Confirm the password",
  confirm: "Confirm",
  passwordChanged: "Пароль успешно изменён",
  oldPassword: "Old password",
  newPassword: "New password",
  passwordsNotEqual: "Passwords do not match",
  apiKeys: "API Keys",
  exceptionDates: "Exception dates",
  selectDate: "Select date",
  deleteDate: "Delete date",
  deleteDateConfirmation: "Are you sure you want to delete the date ？",
  cancelAllChangesConfirmation: "Are you sure you want to undo all changes ？",
  createQuestion: "Create question",
  enterID: "Enter ID",
  byDefault: "By default",
  close: "Close",
  surveyStarting: "Survey start",
  surveyEnding: "Survey end",
  dbSubscribersCount: "Number of recipients from the database",
  sbSubscribersHelpTxt: "Number of distribution recipients",
  respondentsCount: "Number of respondents",
  respondentsHelpTxt: "Number of respondents who opened the survey or scanned the QR code",
  participantsCount: "Number of participants",
  participantsHelpTxt: "Number of respondents who answered at least one question",
  endedSurveyCount: "Finished the poll",
  endedSurveyHelpTxt: "Number of respondents who answered all questions",
  download: "Download",
  downloadCsv: "Download CSV",
  downloadExcel: "Download Excel",
  answerText: "Answer text",
  answerTextOnEn: "Answer text in English",
  answerTextOnKk: "Answer text in Kazakh",
  answerTextOnRu: "Answer text in Russian",
  answerTextOnFrench: "Answer text in French",
  answerTextOnTurkish: "Answer text in Turkish",
  answerTextOnUzbek: "Answer text in Uzbek",
  text: "Text",
  textOnEn: "Text in English",
  textOnKk: "Text in Kazakh",
  textOnRu: "Text in Russian",
  textOnFrench: "Text in French",
  textOnTurkish: "Text in Turkish",
  textOnUzbek: "Text in Uzbek",
  selectFromList: "Select from list",
  operator: "Operator",
  before: "Before",
  after: "After",
  age: "Age",
  brand: "Brand",
  name: "Name",
  gender: "Gender",
  activationDate: "Activation date",
  title: "Name",
  titleOnEn: "Name in English",
  titleOnKk: "Name in Kazakh",
  titleOnRu: "Name in Russian",
  titleOnFrench: "Name in French",
  titleOnTurkish: "Name in Turkish",
  titleOnUzbek: "Name in Uzbek",
  dateCreatedSuccessfully: "Date created",
  dateDeleted: "Date deleted",
  apiKeyCreated: "API Key created",
  apiKeyDeleted: "API Key deleted",
  questionCreated: "Question created",
  questionDeleted: "Question deleted",
  surveyCreated: "Survey created",
  surveyDeleted: "Survey deleted",
  surveyPaused: "Survey paused",
  surveyEnded: "Survey finished",
  surveyDuplicated: "Survey duplicated",
  webhookCreated: "Webhook created",
  webhookDeleted: "Webhook deleted",
  qrCreated: "QR Code created",
  fileDeleted: "File deleted",
  fileUploaded: "File uploaded.",
  fileUpload: "File upload",
  fileDownloaded: "File downloaded",
  subscribersCountInFile: "Number of respondents in the file: ",
  defaultError: "An error occurred",
  surveyStarted: "Survey started",
  whileSaving: "while saving",
  fileAcceptType: "Supported file format",
  pleaseEnterUserName: "Please enter a username",
  pleaseEnterPassword: "Please enter a password",
  web: "Web",
  sms: "SMS",
  changePassword: "Change password",
  passwordChange: "Password change",
  passwordChangeConfirm: "Are you sure you want to change your password ？",
  thisQuestionIsCondition: "This question is a condition for displaying another question",
  maxFileWeightError: "Maximum file size: 100KB",
  invalidImageSize: "Invalid image size",
  differentAspectRatio: "Different aspect ratio",
  rating: "Rating",
  slider: "Slider",
  dropdown: "Dropdown",
  radioButtons: "Radio Buttons",
  customSlider: "Custom Slider",
  selectType: "Select type",
  maxValue: "Maximum value",
  selectMaxValue: "Select max. value",
  loyaltyIndex: "Net Promoter Score (NPS®)",
  languages: "Languages",
  contacts: "Contacts",
  contactsHelpTxt: "Contacts",
  fact: "Fact",
  russian: "Russian",
  kazakh: "Kazakh",
  english: "English",
  turkish: "Turkish",
  uzbek: "Uzbek",
  french: "French",
  code: "Code",
  deleteLanguageConfirmation: "Are You sure You want to remove the language ？",
  languagesHelpTxt: "List of available languages to add to surveys.",
  languageCreated: "Language created",
  languageDeleted: "Language deleted",
  prolongSurvey: "Prolong survey",
  clear: "Clear",
  forgotPassword: "Forgot password？",
  register: "Register",
  backToLogin: "Back to login",
  phoneNumber: "Phone number",
  invalidPhoneNumber: "Invalid phone number",
  fullName: "Full name",
  email: "Email",
  registration: "Registration",
  passwordRecovery: "Password recovery",
  codeValidation: "Code validation",
  sendNewPassword: "Send new password",
  invalidEmail: "Invalid Email",
  searchByTags: "Search by tags",
  closeTabText: "Survey completed, close the tab using your browser functionality.",
  yourAnswer: "Your answer...",
  registrationSuccess: "Registration completed successfully",
  registrationError: "Registration error",
  enterValidationCode: "Enter the received code",
  codeValidationError: "The entered confirmation code is incorrect.",
  createdAt: "Created: ",
  questionsDragDropWarningText: "If the question had display conditions, they may not work when the question is moved",
  youForgotEndBlock: "Oops! Looks like you forgot the final block",
  addAutomaticallyAndStart: "Add automatically and start survey",
  backToQuestionsTable: "Back to questions table",
  pageName: "Page title",
  pageDisplayCondition: "Page condition",
  addCondition: "Add condition",
  removeCondition: "Remove condition",
  addQuestion: "Add a question",
  questionnaire: "Questionnaire",
  answers: "Answers",
  variant: "Option",
  addAnswerVariant: "Add an answer option",
  areYouSureYouWantToLeaveEditPage: "Are you sure you want to leave the edit page ？",
  youWillLostYourChanges: "All unsaved data will be lost.",
  checkYourMailMessage: "An email has been sent to you, please check your email.",
  passwordReset: "Password reset",
  linkExpiredMessage: "Looks like the link is outdated. Try again.",
  mailSendLimitReachedError: "Limit is exceeded. Please try later.",
  content: "Content",
  includeYourChoiceInAnswers: 'Include "your choice" in answers',
  titleForYourChoice: 'Title for "your choice"',
  addParam: "Add parameter",
  addCriteria: "Add Criteria",
  visualRow: "Visual range",
  questionDisplayLogic: "Question Display Logic",
  addConditionAccordingToPrevQuestion:
    "Add a question display condition depending on the answer to the previous question",
  displayIfAnswerOn: "Show if answer to: ",
  displayOnlyContactsWhich: "Show only to contacts who have: ",
  newQuestion: "New question",
  youHaveUncompletedNewQuestionWarning: "You have an unsaved new question",
  infoBlockText: "Info block text",
  iAgreeWith: "I agree to the ",
  termsOfUse: "Terms of Use",
  limitReached: "Limit is exceeded",
  change: "Change",
  identifier: "Identifier",
  size: "Size",
  copyImage: "Copy image",
  sendImage: "Send image",
  successCopiedToClipBoard: "Copied to clipboard",
  accessDenied: "Access denied.",
  iin: "IIN",
  addCustomRegexp: "Add Regular Expression Manually",
  regexp: " Regular expression",
  enterRegexp: "Enter regular expression",
  validateResponse: "Validate response",
  documentation: "Documentation",
  defaultLabel: "Default",
  emailProvidersMainDescription:
    "You can add the provider's e-mail from the list, so that you can then select it inside the survey.",
  emailProviders: "E-mail providers",
  providerDescription: "Provider description",
  connectionName: "Connection name",
  provider: "Provider",
  addpostmarkProvider: "Add Postmark provider",
  changepostmarkProvider: "Add Postmark provider",
  addsmtpProvider: "Add SMTP provider",
  changesmtpProvider: "Add SMTP provider",
  providersName: "Provider name",
  emailProviderDeleted: "E-mail provider deleted",
  emailProviderCreated: "E-mail provider created",
  emailProviderChanged: "E-mail provider changed",
  deleteEmailProviderConfirmation: "Are you sure you want to delete the E-mail provider ？",
  titleFont: "Title font",
  textFont: "Text font",
  selectFont: "Select font",
  distributionAndNotification: "Distributions and notifications",
  distributionList: "Distribution list",
  uniqueUrlsAndQrCodes: "Unique survey URLs and QR-codes",
  distributionDate: "Distribution date",
  distributionChannel: "Distribution channel",
  respondentsSource: "Source of respondents",
  uniqueUrlParam: "Unique URL parameter",
  surveysURL: "Survey URL",
  fileFromDisc: "File from disk",
  from: "from ",
  to: "to ",
  distributionDeleteConfirmation: "Are you sure you want to delete the newsletter ？",
  creatingDistribution: "Distribution creation",
  editingDistribution: "Distribution editing",
  distributionStartConfirmation: "Are you sure you want to start the distribution ？",
  distributionFinishConfirmation: "Are you sure you want to finish the distribution ？",
  distributionPauseConfirmation: "Are you sure you want to pause the distribution ？",
  attributeContainingSendingAddress: "Attribute containing the sending address",
  attributeContainingSendingAddressHelpText:
    "A contact attribute that contains the recipient's phone number or email address.",
  subscriberSelectionLogic: "Subscriber sampling logic",
  anonymousSurvey: "Anonymous survey",
  fill: "Fill in",
  qrAndUrlCreating: "Creation of QR/URL",
  shareQrAnsUrl: "Share QR/URL",
  copy: "Copy",
  integrationsDocHelpText: 'See "Settings/API Keys" section to create API Authorization Keys',
  triggerSurveySettings: "Setting up triggered surveys",
  triggerCondition: "Trigger Conditions",
  areYouSureYouWantToDeleteFiltersGroup: "Are you sure you want to delete the filter group ？",
  saveChanges: "Save Changes",
  qrCodeDeleted: "QR-Code deleted",
  qrCodeUpdated: "QR-Code updated",
  qrCodeCreated: "QR-Code created",
  addFromFile: "Add from file",
  addContact: "Add a contact",
  contactsUploadCsvHelpText: "csv (see example format)",
  contactsUploadXlsxHelpText: "xlsx (see example format)",
  contactsUploading: "Loading contacts",
  filesType: "File type",
  source: "Source",
  upload: "Upload",
  contactDeleted: "Contact deleted",
  contactUpdated: "Contact updated",
  contactCreated: "Contact created",
  noFileSelected: "Select file",
  updateAt: "Update date",
  attributes: "Attributes",
  keyField: "Key field",
  warningWhenUploadingFile: {
    title: "Warning before downloading file",
    subTitle: "Please make sure the following conditions are met:",
    first: "1. The file format matches the file type",
    second: "2. The file is readable",
    third: "3. File size does not exceed 1 MB",
    fourth: "4. The file format matches the selected type",
  },
  watchShort: "see",
  exampleHere: "example here",
  fileUploadingError: "File upload error",
  deactivated: "Deactivated",
  atLeastOneDistributionRequired: "At least one mailing is required",
  smsProviders: "SMS Providers",
  smsProvidersMainDescription: "You can add an SMS provider to select it later inside the survey.",
  sender: "Sender",
  surveyActivated: "Survey activated",
  surveyDeactivated: "Survey deactivated",
  activateSurvey: "Activate",
  deactivateSurvey: "Deactivate",
  activateSurveyConfirmation: "Are you sure you want to activate the survey ？",
  deactivateSurveyConfirmation: "Are you sure you want to deactivate the survey ？",
  distributionFinished: "Distribution finished",
  distributionPaused: "Distribution paused",
  distributionStarted: "Distribution started",
  distributionUpdated: "Distribution updated",
  distributionCreated: "Distribution created",
  distributionDeleted: "Distribution deleted",
  addAutomaticallyAndStartDistribution: "Add automatically and start distribution",
  atLeastOneQuestionIsRequired: "To start distribution you need to create at least one question in the survey",
  distributionsAreTemporaryPausedHelpTxt:
    "Attention! Distributions are temporarily suspended due to the deactivation of the survey.",
  allTempPausedDistributionsWillContinue: "All temporarily suspended distributions will resume.",
  allDistributionsWillBePausedTemporary: "All distributions will be temporarily suspended.",
  contactsSettings: "Contact settings",
  addAttribute: "Add attribute",
  attributeName: "Attribute name",
  datatype: "Data type",
  whoAdded: "Added by",
  whenAdded: "Added at",
  attributeDeleted: "Attribute deleted",
  attributeCreated: "Attribute created",
  attributeUpdated: "Attribute updated",
  attributeDeleteConfirmation: "Are you sure you want to remove the attribute ？",
  newAttribute: "Attributes",
  contact: "Contact",
  contactsSettingsHelpTxt: "List of available attributes to add to contacts.",
  newContact: "Contacts",
  updateContact: "Edit contact",
  labelMustBeUnique: "Property key must be unique",
  contactDeleteConfirmation: "Are you sure you want to delete the contact ？",
  signInWithGoogle: "Sign in with Google",
  review: "Review",
  reports: "Reports",
  answersByDays: "Replies by day",
  loading: "Loading",
  generalStatistics: "General statistics",
  viewed: "Viewed",
  surveysStarted: "Polls started",
  avgFinishTime: "Average completion time",
  finishedProportion: "Share of completed",
  finishedCount: "Completed",
  avgTime: "Average time",
  byDeviceType: "By device type",
  byLanguage: "By language",
  byDistributionType: "By mailing type",
  numberOfInteractionsByCategory: "Number of interactions by category",
  beenFinished: "Completed",
  notFinished: "Not completed",
  resetFilters: "Reset filters",
  columns: "Speakers",
  deleteConfirmation: "Are you sure you want to delete the line ？",
  selectColumns: "Select columns",
  columnsNotFound: "Columns not found",
  atLeastOneColumnsMustBeSelected: "At least one column must be selected",
  deleteSelectedElementsConfirmation: "Are you sure you want to remove all selected items ？",
  report: "Report",
  questionsInSurvey: "Questions in a survey",
  answersOption: "Answer option",
  percentOfAnswers: "% responses",
  display: "Show",
  error: "Error",
  resendCodeAgain: "Send code again",
  resendCode: "Send code",
  deletedSuccessfully: "Successfully deleted",
  answered: "answered",
  filesUploaded: "Files uploaded",
  unavailable: "Unavailable",
  formatTime: {
    min: "min",
    hrs: "hours",
    sec: "sec",
    day: "day",
    day2form: "days",
    days: "days",
    hour: "hour",
    hour2form: "hours",
    hours: "hours",
    minute: "minute",
    minute2form: "minutes",
    minutes: "minutes",
    second: "second",
    second2form: "seconds",
    seconds: "seconds",
  },
  accountSettings: "Account settings",
  myAccount: "My account",
  currentTariff: "Current tariff plan",
  timezone: "Timezone",
  currentTimezone: "Current timezone",
  tariffPlans: "Tariff plans",
  tariffPlan: "Tariff plan",
  selectTimezone: "Select timezone",
  notChosen: "Not chosen",
  selectThePlanThatSuitsYouBest: "Select the plan that suits you best",
  choose: "Choose",
  currentPlan: "Current plan",
  selectPaymentMethod: "Select a payment method",
  paymentPage: "Payment page",
  moreAboutTariffs: "More about tariffs",
  role: "Role",
  addUser: "Add user",
  subUserPageHelpTxt:
    "Added users will have access to view each other's surveys, including yours, in accordance with the assigned role.",
  userDeleteConfirmationText: "Are you sure you want to delete the user？",
  roles: "Roles",
  users: "Users",
  share: "Share",
  shareSurveyResults: "Share survey results",
  accessOptions: "Access options",
  onlyForRegisteredUsers: "Only for registered users",
  allWhoHasLink: "Anyone with a link",
  allWhoHasLinkAndPassword: "Everyone who has the link and password",
  sendLink: "Send link",
  linkSent: "Link sent",
  enterEmail: "Enter e-mail",
  userNotFound: "User is not found",
  aiAssistant: "AI assistant",
  extraOptions: "Extra options",
  smsProviderDeleted: "SMS provider deleted",
  smsProviderCreated: "SMS provider created",
  smsProviderChanged: "SMS provider changed",
  invalidUrl: "Invalid URL",
  selectAll: "Select all",
  questionsFilter: "Questions filter",
  selectQuestions: "Select questions",
  createAndContinue: "Create & Continue",
  downloadQr: "Download QR",
  showQr: "Show QR",
  copyQr: "Copy QR",
  createDistribution: "Create distribution",
  createQrCode: "Create QR-code",
  questionsQuantity: "Questions quantity",
  standard: "Standard",
  rounded: "Rounded",
  dots: "Dots",
  selectColor: "Select color",
  style: "Style",
  deleteLogo: "Remove logo",
  youHaveUnfilledFields: "You have required fields that are not filled in.",
  message: "Message",
  thankYouPage: "Thank you page",
  welcomePage: "Welcome page",
  footer: {
    termsOfUse: "Terms of Use",
    privacyPolicy: "Privacy Policy",
    reference: "Reference",
  },
  distributionChannelCreation: "Creating a distribution channel",
  saveAndCopy: "Save & copy",
  saveAndShare: "Save & share",
  linkToSurvey: "Survey link",
  shareOn: "Share on ",
  emailDistribution: "Sending e-mails",
  smsDistribution: "Sending SMS",
  dateAndTime: "Date & time",
  additional: "Additionally",
  moreDetails: "More details",
  month: "month",
  distributionChannelsList: "Distribution channels' list",
  channelName: "Distribution channel's name",
  questionMediaBanner: {
    label: "Media banner",
    title: "Click or drag file to this area to upload",
    description: "Supported files: JPG, JPEG, PNG, GIF, MP4.",
    deleteConfirmationText: "Are you sure you want to remove the media banner?",
    uploadedSuccessfullyText: "Media file uploaded.",
  },
  color: "Color",
  fileFormat: "File format",
  buy: "Purchase",
  redirectUrl: "Redirect url",
  identifierErrorMsg: "The identifier can only contain Latin letters, numbers, dashes and underscores.",
  socialMedia: "Social media",
  redirectToAnotherSite: "Redirect to another site",
  previewOfTheSurvey: "Preview of the survey",
  firstname: "First name",
  lastname: "Last name",
  youHaveReachedMaxSurveys: "You have reached the maximum number of surveys available for your subscription.",
  youHaveReachedMaxContacts: "Contact limit exceeded",
  showProgressBar: "Show progress bar",
  templateTooltips: {
    SINGLE_CHOICE: "To get the only answer to the question from the suggested options",
    MULTIPLE_CHOICE: "Provides an opportunity to choose several answers to a question",
    CSI: "Allows the respondent to evaluate different parameters according to the proposed criteria. This data can help calculate the customer satisfaction index.",
    NPS: "Offer to evaluate your question in the form of icons (“star”, “smileys”, “hearts”, digital row”), a slider, a drop-down list. Allows you to rate on a 5 or 10-point scale.",
    FILE_UPLOAD:
      "Give the respondent the opportunity to attach files to their answer (resume, portfolio, photos and other images)",
    INFO_BLOCK: "Show the respondent an arbitrary message . Does not require an answer.",
    START_BLOCK:
      "This is the start page of the survey. Greet the respondent and tell them about your survey. Does not require an answer.",
    END_BLOCK:
      "The final page of the survey displays a message to the respondent at the end of the survey. Does not require an answer. You can redirect to your site.",
    EMAIL: "A template with the ability to check the format of the entered email address",
    IIN: "The template for the IIN request",
    NUMBER: "Request the respondent's phone number",
    TEXT: "To collect answers to open questions.",
    DATE: "Ask the respondent for the required date (to indicate the past or future date of the service)",
    DATETIME:
      "Ask the respondent for the required date and exact time (to indicate the past or future date and time of service provision)",
  },
  propertyKey: "Variable name for text templates",
  theVariableIsRequiredForTexts:
    "The variable is required for use in texts when accessing the user. For example, you could use a message template in a survey where you address all respondents by name.",
  patternErrorText: 'URL-link must start with "http://" or "https://"',
  dataTypeText: "Text",
  integerDataType: "Integer",
  numberWithFractionalPart: "Number with fractional part",
  stringList: "List (separator “,“)",
  dataTypeDate: "Date",
  emailDataType: "E-mail",
  mobileNumber: "Mobile number",
  emailError: "Enter a correct email",
  phoneError: "Enter a correct phone number",
  basedOnPreviousResponses: "Based on previous responses",
  basedOnContactData: "Based on contact data",
  highestScoreLabel: "Text for the highest rate",
  lowestScoreLabel: "Text for the lowest rate",
  npsTrademark:
    "NPS®, Net Promoter & Net Promoter Score are registered trademarks of Satmetrix Systems, Inc., Bain & Company, and Fred Reichheld",
  attentionAnswerNotRequired: "Please note that the answer to the referring question is optional.",
  distributionStartConfirmationTooltip: "Start sending newsletter",
  distributionPauseConfirmationTooltip: "Pause mailing",
  distributionFinishConfirmationTooltip: "Stop sending",
  fixedNumberOfSelectedOptions: "Fixed number of selected options",
  ranging: "Ranging",
  mixOptions: "Mix options",
  hideLineNumbers: "Hide line numbers",
  noAnswersAdded: "Answers not yet added",
  subscriptionSurvey: "Survey Suite Subscription",
};
