import { createAsyncThunk } from "@reduxjs/toolkit";
import { SurveyEditType, SurveyFilter, SurveyType } from "../../../types/surveyType";
import { DELETE, GET, POST, PUT } from "../../../types/httpMethod";
import { requestApi } from "../../../utils/requestApi";
import {
  resetSurveyInstanceFilters,
  resetSurveyInstancePageable,
  setChannel,
  setCurrent,
  setCurrentInfo,
  setIsAnythingEdited,
  setList,
  setSurveyInstance,
  setSurveyInstanceData,
  setSurveyInstanceFilters,
  setSurveyInstancePageable,
  updateCurrent,
  updateInList,
} from "./surveysSlice";
import { message } from "antd";
import store, { RootState } from "../../index";
import { PagedResponseType } from "../../../types/pagedResponseType";
import { setSurveyEditActiveStep, setSurveysPageable } from "../persisted/persistedActions";
import { APP_PREFIX, DEFAULT_DATE_FORMAT } from "../../../utils";
import { createQuestionsFromTemplate, setQuestions } from "../questions/questionsService";
import { Pageable } from "../../../types/pageable";
import { SurveyInfo } from "../../../types/surveyInfo";
import { I18n } from "react-redux-i18n";
import { getTags } from "../../../utils/getTags";
import { SURVEY_DATA } from "../../../utils/surveyTemplate";
import setMaxTimeToDate from "../../../utils/setMaxTimeToDate";
import dayjs from "dayjs";
import { ChannelEnum } from "../../../types/channelEnum";
import {
  SurveyInstanceFiltersType,
  SurveyInstanceResponseType,
  SurveyInstanceStateType,
} from "../../../types/surveyInstance";
import { downloadFile } from "../../../utils/downloadFile";
import { FileFormat } from "../../../types/fileFormatEnum";
import { StepsEnum } from "../../../types/surveyEditTabsEnum";

export const getSurveys = createAsyncThunk<void, undefined>("surveys/getSurveys", async (_, { dispatch }) => {
  try {
    const page: Pageable = store.getState().persisted.surveysPagePageable;
    const surveyFilter: SurveyFilter = {
      ...store.getState().persisted.surveysPageFilters,
      tags: getTags(window.location.search),
    };
    const res: PagedResponseType<SurveyType> = await requestApi(
      POST,
      "/surveys/paginated",
      surveyFilter,
      false,
      true,
      {},
      { ...page, total: undefined },
    );
    dispatch(setSurveysList(res.content));
    dispatch(setSurveysPageable({ ...page, total: res.totalElements }));
  } catch (e) {
    throw e;
  }
});

export const getSurveyById = createAsyncThunk<void, string>("surveys/getSurveyById", async (id, { dispatch }) => {
  try {
    const res: SurveyType = await requestApi(GET, `/surveys/${id}`, {});
    dispatch(setCurrentSurvey(res));
    dispatch(setQuestions(res?.questions || []));
    dispatch(setCommunicationChannel(res?.communicationChannel));
  } catch (e) {
    throw e;
  }
});

export const getSurveyInfo = createAsyncThunk<void, string>("surveys/getSurveyInfo", async (id, { dispatch }) => {
  try {
    const res: SurveyInfo = await requestApi(GET, `/surveys/${id}/info`, {});
    dispatch(setCurrentSurveyInfo(res));
  } catch (e) {
    throw e;
  }
});

export const updateSurvey = createAsyncThunk<
  void,
  {
    readonly survey: SurveyType;
    readonly fromEditable?: boolean;
  }
>("surveys/updateSurvey", async ({ survey, fromEditable = false }, { dispatch }) => {
  try {
    const res: SurveyType = await requestApi(PUT, `/surveys/${survey?.surveyId}`, survey);
    if (fromEditable) {
      dispatch(updateSurveyInList(res));
      dispatch(setCurrentSurvey(undefined));
    } else {
      dispatch(setCurrentSurvey(res));
    }
    message.success(I18n.t("changesSaved"));
  } catch (e) {
    console.log(e);
  } finally {
    dispatch(setIsAnythingEdited(false));
  }
});

export const createSurvey = createAsyncThunk<void, SurveyEditType>(
  "surveys/createSurvey",
  async (survey, { dispatch }) => {
    try {
      const res = await requestApi(POST, "/surveys", survey);
      dispatch(setCurrentSurvey(res));
      dispatch(setCommunicationChannel(res?.communicationChannel));
      dispatch(setSurveyEditActiveStep(StepsEnum.QUESTIONS));
      message.success(I18n.t("surveyCreated"));
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setIsAnythingEdited(false));
    }
  },
);

export const createSurveyFromTemplate = createAsyncThunk<void, (url: string) => void>(
  "surveys/createSurveyFromTemplate",
  async (navigate, { dispatch }) => {
    try {
      const endDate = setMaxTimeToDate(dayjs(new Date()).add(1, "month"), DEFAULT_DATE_FORMAT);
      const survey = { ...SURVEY_DATA, endDate };
      await requestApi(POST, "/surveys", survey)
        .then((res) => {
          dispatch(createQuestionsFromTemplate(res?.surveyId));
          return res?.surveyId;
        })
        .then((surveyId) => navigate(`${APP_PREFIX}/surveys/${surveyId}/edit`))
        .then(() => message.success(I18n.t("surveyCreated")));
    } catch (e) {
      throw e;
    }
  },
);

type StatusUpdateType = {
  readonly surveyId: string;
  readonly withUpdate?: boolean;
};

export const startSurvey = createAsyncThunk<void, StatusUpdateType>(
  "surveys/startSurvey",
  async ({ surveyId, withUpdate = true }, { dispatch }) => {
    try {
      await requestApi(PUT, `/surveys/${surveyId}/start`, {});
      message.success(I18n.t("surveyStarted"));
    } catch (e) {
      console.log(e);
    } finally {
      if (withUpdate) {
        dispatch(getSurveys());
      }
    }
  },
);

export const pauseSurvey = createAsyncThunk<void, StatusUpdateType>(
  "surveys/pauseSurvey",
  async ({ surveyId, withUpdate = true }, { dispatch }) => {
    try {
      await requestApi(PUT, `/surveys/${surveyId}/pause`, {});
      if (withUpdate) dispatch(getSurveys());
      message.success(I18n.t("surveyPaused"));
    } catch (e) {
      console.log(e);
    }
  },
);

export const finishSurvey = createAsyncThunk<void, StatusUpdateType>(
  "surveys/finishSurvey",
  async ({ surveyId, withUpdate = true }, { dispatch }) => {
    try {
      await requestApi(PUT, `/surveys/${surveyId}/finish`, {});
      if (withUpdate) dispatch(getSurveys());
      message.success(I18n.t("surveyEnded"));
    } catch (e) {
      console.log(e);
    }
  },
);

export const deleteSurvey = createAsyncThunk<void, string>("surveys/deleteSurvey", async (surveyId, { dispatch }) => {
  try {
    await requestApi(DELETE, `/surveys/${surveyId}`, {});
    dispatch(getSurveys());
    message.success(I18n.t("surveyDeleted"));
  } catch (e) {
    console.log(e);
  }
});

export const deactivateSurvey = createAsyncThunk<void, string>(
  "surveys/deactivateSurvey",
  async (surveyId, { dispatch }) => {
    try {
      await requestApi(PUT, `/surveys/${surveyId}/deactivate`, {});
      dispatch(getSurveys());
      message.success(I18n.t("surveyDeactivated"));
    } catch (e) {
      console.log(e);
    }
  },
);

export const activateSurvey = createAsyncThunk<void, string>(
  "surveys/activateSurvey",
  async (surveyId, { dispatch }) => {
    try {
      await requestApi(PUT, `/surveys/${surveyId}/activate`, {});
      dispatch(getSurveys());
      message.success(I18n.t("surveyActivated"));
    } catch (e) {
      console.log(e);
    }
  },
);

export const duplicateSurvey = async (surveyId: string, dispatch: any): Promise<any> => {
  try {
    const res = await requestApi(POST, `/surveys/${surveyId}/clone`, {});
    message.success(I18n.t("surveyDuplicated"));
    dispatch(getSurveys());
    return res;
  } catch (e) {
    console.error("Failed to duplicate survey:", e);
    throw e;
  }
};

export const changeSurveyIcon = createAsyncThunk<
  void,
  {
    readonly surveyId: string;
    readonly file: any;
    readonly iconType: "icon" | "favicon";
  }
>("surveys/changeSurveyIcon", async ({ surveyId, file, iconType }, { dispatch }) => {
  await requestApi(
    POST,
    `/surveys/${surveyId}/${iconType}`,
    {
      file,
    },
    true,
  )
    .then((res) => {
      if (res) {
        dispatch(
          updateCurrentSurvey({
            [iconType === "favicon" ? "faviconPath" : "logoPath"]: res,
          }),
        );
      }
    })
    .catch(() => message.error(`${I18n.t("errorWhileSaving")} ${iconType}`));
});

export const setSurveysList = createAsyncThunk<void, Array<SurveyType>>(
  "surveys/setSurveysList",
  (payload, { dispatch }) => {
    dispatch(setList(payload));
  },
);

export const updateCurrentSurvey = createAsyncThunk<void, any>(
  "surveys/updateCurrentSurvey",
  (payload, { dispatch }) => {
    dispatch(updateCurrent(payload));
  },
);

export const setCurrentSurvey = createAsyncThunk<void, SurveyType | undefined>(
  "surveys/setCurrentSurvey",
  (payload, { dispatch }) => {
    dispatch(setCurrent(payload));
  },
);

export const updateSurveyInList = createAsyncThunk<void, SurveyType>(
  "surveys/updateSurveyInList",
  (payload, { dispatch }) => {
    dispatch(updateInList(payload));
  },
);

export const setCurrentSurveyInfo = createAsyncThunk<void, SurveyInfo | undefined>(
  "surveys/setCurrentSurvey",
  (payload, { dispatch }) => {
    dispatch(setCurrentInfo(payload));
  },
);

export const setCommunicationChannel = createAsyncThunk<void, ChannelEnum | undefined>(
  "surveys/setCommunicationChannel",
  (channel, { dispatch }) => {
    dispatch(setChannel(channel));
  },
);

export const resetSurveysData = createAsyncThunk<void, undefined>("surveys/resetSurveysData", (_, { dispatch }) => {
  dispatch(setCurrentSurvey(undefined));
  dispatch(setCurrentSurveyInfo(undefined));
  dispatch(setCommunicationChannel(undefined));
  dispatch(setSurveysList([]));
});

export const getSurveyInstances = createAsyncThunk<SurveyInstanceResponseType | undefined, string>(
  "surveys/getSurveyInstances",
  async (surveyId, { dispatch }) => {
    try {
      const state: RootState = store.getState();
      const page = state.surveys.surveyInstance.pageable;
      const filters = state.surveys.surveyInstance.filters || {};

      const res: SurveyInstanceResponseType = await requestApi(
        POST,
        `/survey-instance/${surveyId}`,
        filters,
        false,
        true,
        {},
        { ...page, total: undefined },
      );

      dispatch(setSurveyInstanceData$(res));
      dispatch(setSurveyInstancePageable$({ ...page, total: res.totalElements }));

      return res;
    } catch (e) {
      throw e;
    }
  },
);

export const getSurveyReportXlsx = createAsyncThunk<void, string>("surveys/getSurveyReportXlsx", async (surveyId) => {
  try {
    const filters = store.getState().surveys.surveyInstance.filters;
    const fileName = store.getState().surveys.current?.surveyName ?? "results-file";
    const res = await requestApi(
      POST,
      `/surveys/${surveyId}/results/xlsx`,
      filters,
      false,
      true,
      {},
      {},
      undefined,
      "blob",
    );
    downloadFile(res, fileName, FileFormat.EXCEL);
  } catch (e) {
    throw e;
  }
});

export const getSurveyReportPdf = createAsyncThunk<void, string>("surveys/getSurveyReportPdf", async (surveyId) => {
  try {
    const fileName = store.getState().surveys.current?.surveyName ?? "results-file";
    const res = await requestApi(GET, `/surveys/${surveyId}/results/pdf`, {}, false, true, {}, {}, undefined, "blob");
    downloadFile(res, fileName, FileFormat.PDF);
  } catch (e) {
    throw e;
  }
});

export const getExampleFile = createAsyncThunk<void, void>("surveys/getExampleFile", async () => {
  try {
    const res = await requestApi(GET, `/contact/download/example/xlsx`, {}, false, true, {}, {}, undefined, "blob");
    const fileName = "example";
    downloadFile(res, fileName, FileFormat.EXCEL);
  } catch (e) {
    console.error("Failed to download file:", e);
  }
});

export const deleteSurveyInstanceById = createAsyncThunk<
  void,
  {
    surveyId: string;
    instanceId: string;
  }
>("surveys/deleteSurveyInstanceById", async ({ surveyId, instanceId }, { dispatch }) => {
  try {
    await requestApi(DELETE, `/survey-instance/${instanceId}`, {});

    const state: RootState = store.getState();
    const { pageable: page, data } = state.surveys.surveyInstance;

    const currentPageRecords = data?.instances?.length ?? 0;
    const isLastRecordOnPage = currentPageRecords === 1;

    if (isLastRecordOnPage && page.page > 0) {
      dispatch(setSurveyInstancePageable$({ ...page, page: page.page - 1 }));
    }

    await dispatch(getSurveyInstances(surveyId));

    message.success(I18n.t("deletedSuccessfully"));
  } catch (e) {
    console.error(e);
  }
});

export const deleteSurveyInstancesByIds = createAsyncThunk<
  Promise<boolean>,
  {
    surveyId: string;
    instanceIds: Array<string>;
  }
>("surveys/deleteSurveyInstanceById", async ({ surveyId, instanceIds }, { dispatch }) => {
  try {
    await requestApi(DELETE, `/survey-instance/array/delete`, instanceIds);
    dispatch(getSurveyInstances(surveyId));
    message.success(I18n.t("deletedSuccessfully"));
    return Promise.resolve(true);
  } catch (e) {
    return Promise.resolve(false);
  }
});

export const setSurveyInstance$ = createAsyncThunk<void, SurveyInstanceStateType | undefined>(
  "surveys/setSurveyInstance$",
  (instance, { dispatch }) => {
    dispatch(setSurveyInstance(instance));
  },
);

export const setSurveyInstanceFilters$ = createAsyncThunk<void, SurveyInstanceFiltersType>(
  "surveys/setSurveyInstanceFilters$",
  async (filters, { dispatch }) => {
    dispatch(setSurveyInstanceFilters(filters));
    dispatch(resetSurveyInstancePageable$());

    const surveyId = store.getState().surveys.current?.surveyId;
    if (surveyId) {
      await dispatch(getSurveyInstances(surveyId));
    }
  },
);

export const resetSurveyInstanceFilters$ = createAsyncThunk<void, undefined>(
  "surveys/resetSurveyInstanceFilters$",
  (_, { dispatch }) => {
    dispatch(resetSurveyInstanceFilters());
    dispatch(resetSurveyInstancePageable$());
  },
);

export const setSurveyInstancePageable$ = createAsyncThunk<void, Pageable>(
  "surveys/setSurveyInstancePageable$",
  (pageable, { dispatch }) => {
    dispatch(setSurveyInstancePageable(pageable));
  },
);
export const resetSurveyInstancePageable$ = createAsyncThunk<void, undefined>(
  "surveys/resetSurveyInstancePageable$",
  (_, { dispatch }) => {
    dispatch(resetSurveyInstancePageable());
  },
);

export const setSurveyInstanceData$ = createAsyncThunk<void, SurveyInstanceResponseType>(
  "surveys/setSurveyInstanceData$",
  (data, { dispatch }) => {
    dispatch(setSurveyInstanceData(data));
  },
);
