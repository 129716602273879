import React, { FC, ReactNode } from "react";
import { Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { FormItemType } from "../../../types/formItemType";
import { DEFAULT_VALIDATION_MESSAGE } from "../../../utils";
import { I18n } from "react-redux-i18n";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";

type InputFormItemProps = FormItemType & {
  readonly onChange?: (value: string) => void;
  readonly addonBefore?: ReactNode;
  readonly addonAfter?: ReactNode;
  readonly restField?: any;
  readonly showTextArea?: boolean;
  readonly showError?: boolean;
  readonly helpLabel?: string;
  readonly type?: "string" | "number" | "password";
  readonly max?: number;
  readonly min?: number;
  readonly pattern?: RegExp;
  readonly patternErrorText?: string;
  readonly rules?: any[];
  readonly handlePhoneInputChange?: boolean;
};

const InputFormItemComponent: FC<InputFormItemProps> = ({
  name,
  label,
  required = true,
  disabled,
  onChange,
  noStyle,
  style,
  addonBefore,
  addonAfter,
  placeHolder,
  showTextArea = false,
  showError,
  helpLabel,
  pattern,
  rows,
  prefix,
  autoSize,
  type,
  minCharacters,
  maxCharacters,
  hasFeedback,
  errorText,
  tooltip,
  wrapperStyles,
  initialValue,
  rules = [],
  handlePhoneInputChange = false,
}) => {
  const prop = {
    style: style,
    disabled: disabled,
    placeholder: capitalizeFirstLetter(placeHolder || `${I18n.t("enter")} ${label || ""}`),
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    if (handlePhoneInputChange) {
      if (value && value.charAt(0) !== "+") {
        value = `+${value.replace(/^\+/, "")}`;
      }
    }

    if (onChange) {
      onChange(value);
    }
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <Form.Item
      name={name}
      label={label}
      noStyle={noStyle}
      tooltip={tooltip}
      style={wrapperStyles}
      hasFeedback={hasFeedback}
      initialValue={initialValue}
      rules={[
        { required, message: I18n.t(DEFAULT_VALIDATION_MESSAGE) },
        {
          max: maxCharacters,
          message: I18n.t("maxCharacters"),
        },
        ...(name === "url" && pattern
          ? [
              {
                pattern,
                message: I18n.t("patternErrorText") || errorText,
              },
            ]
          : []),
        ...rules,
      ]}
      {...(showError && { help: errorText || helpLabel, validateStatus: "error" })}
    >
      {showTextArea ? (
        <TextArea {...prop} rows={rows || 5} autoSize={autoSize} onChange={handleTextAreaChange} />
      ) : (
        <Input
          {...prop}
          type={type}
          prefix={prefix}
          max={maxCharacters}
          min={minCharacters}
          addonAfter={addonAfter}
          addonBefore={addonBefore}
          onChange={handleInputChange}
        />
      )}
    </Form.Item>
  );
};

export default InputFormItemComponent;
